import { useState,useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux'
// @mui
import { Stack, IconButton, InputAdornment, TextField,Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
// import AlertError from "../../../components/alerts/AlertsError"

// ----------------------------------------------------------------------

import { getAuth } from '../../../redux/authDucks';

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

const errorAuth = useSelector(store=>store.auth.errorAuth);
const loadAuth = useSelector(store=>store.auth.loadingAuth);
const messageAuth = useSelector(store=>store.auth.message);
const userAuth = useSelector(store=>store.auth.userAuth);
const tokenAuth = useSelector(store=>store.auth.tokenAuth);

const [loading,setLoading]=useState(false);
const [error,setError]=useState('');

const [message, setMessage] = useState(messageAuth);
useEffect(() => {
  setError(errorAuth);
  setMessage(messageAuth);
}, [errorAuth,messageAuth]);

useEffect(()=>{
  setError(errorAuth)
  if( errorAuth!=='') {setLoading(false)}
   },[errorAuth])

useEffect(()=>{
       if(userAuth&&tokenAuth){
        setTimeout(()=>{
          navigate('/dashboard/app');
        },500)
      
        }
     },[loadAuth,tokenAuth]);


const [code, setCode] = useState('');
const handleChangeCode = (event) => {
     setCode(event.target.value);
   };

const [password, setPassword] = useState('');
const handleChangePassword = (event) => {
     setPassword(event.target.value);
  };

const handleClick = (e) => {
    e.preventDefault();
    setLoading(true)
    const  data ={code, password}
      dispatch(getAuth(data));
  };

  return (

  <form onSubmit={handleClick}>
    {message !== '' && (
        <Stack sx={{ width: '100%' }} spacing={1} p={2}>
                <Alert severity="success">{message}</Alert>
              </Stack>
            )}
    {
     error!=="" &&(
      <Stack sx={{ width: '100%' }} spacing={1} p={2} >
        <Alert  severity="error">{error}</Alert>
      </Stack>)
    }
              
      <Stack spacing={3}>
        <TextField 
        required
        name="code" 
        label="User or Email" 
        onChange={handleChangeCode}
        />

        <TextField
        required
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={handleChangePassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} >
        {/* <Checkbox name="remember" label="Remember me" /> */}
        {/* <Link variant="subtitle2" underline="hover"  >
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} >
        Login
      </LoadingButton>
  </form>
  );
}
