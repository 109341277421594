import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';


import { Container } from '@mui/system';
import {  Stack, Card, Paper, Grid,Alert,IconButton, Button ,Box,Snackbar} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import { useDispatch, useSelector } from 'react-redux';
import { MapContainer, TileLayer, Marker, Popup, LayersControl,useMapEvents } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

import L from 'leaflet';

import Moment from 'react-moment';
import { getMap } from '../../redux/mapDucks';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  width: '100%',
  maxWidth: 580,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
}));


const colores = [
  '/assets/images/markers/marker-icon-green.png',
  '/assets/images/markers/marker-icon-black.png',
  '/assets/images/markers/marker-icon-blue.png',
  '/assets/images/markers/marker-icon-red.png',
  '/assets/images/markers/marker-icon-gold.png',
];
const shadow = '/assets/images/markers/marker-shadow.png';


const greenIcon = new L.Icon({
  iconUrl: colores[0],
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const blueIcon = new L.Icon({
  iconUrl: colores[2],
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const blackIcon = new L.Icon({
  iconUrl: colores[1],
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const redIcon = new L.Icon({
  iconUrl: colores[3],
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const yellowIcon = new L.Icon({
  iconUrl: colores[4],
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const Maps = ({usuario,token}) => {
  const [ejecutar, setEjecutar] = useState(1);
  // const [reloj, setReloj] = useState(0);

  const dispatch = useDispatch();

  const hora = new Date();
  const TRAFFICLIGTHLIST = useSelector((store) => store.map.array[0]);
  const TotalTrafficOnline = useSelector((store) => store.map.array[1]);
  const TotalTrafficOffline = useSelector((store) => store.map.array[2]);
  const TotalTrafficEmpalme = useSelector((store) => store.map.array[3]);
  const TotalTrafficControl = useSelector((store) => store.map.array[4]);
  const TotalTrafficLuces = useSelector((store) => store.map.array[5]);

  const user = usuario;
  const [open, setOpen] = useState(false);
  const [message,setMessage] = useState('');
  const [msgcolor, setMsgcolor] = useState('green');

  useEffect(() => {
    dispatch(getMap(token));
  }, [dispatch]);

  useEffect(() => {
    console.log('Update Cada 5 Seg');
    dispatch(getMap(token));
    setTimeout(() => {
      setEjecutar(ejecutar + 1);
      // setReloj(ejecutar);
    }, 5000);
  }, [ejecutar]);


  const handleMarkerClick = (mark,estado,color) => {
    // setReloj(0);
    setMsgcolor(color)
    const msg = <p>
      Codigo: {mark.id} <br/>  
      Nombre {mark.name} <br/> 
      Estado: {estado}<br/>
      Actualizado el <Moment format="DD/MM/YY HH:mm">{mark.updatedAt}</Moment> <br/>
      {usuario.roles!=='user' && 
            <>
      Operador: {mark.operator} 
             <br />
      Signal: {mark.signal} dbm
            </>
            }
                </p>
    setMessage(msg);
    setOpen(true);

  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const markerOperativo = TotalTrafficOnline?.map((mark, index) => {
    return (
        <Marker key={index} position={[mark.latitud, mark.longitud]} 
        icon={greenIcon} eventHandlers={{ click: ()=> handleMarkerClick(mark,'Operativo','#009F3C')}} />
    
      
    );
  });

  const markerOffline = TotalTrafficOffline?.map((mark, index) => {
    return (
        <Marker key={index} 
        position={[mark.latitud, mark.longitud]} 
        icon={blueIcon} 
        eventHandlers={{ click: ()=> handleMarkerClick(mark,'Offline','#1F6BEF')}}/>

    );
  });

  const markerEmpalme = TotalTrafficEmpalme?.map((mark, index) => {
    return (
        <Marker key={index} position={[mark.latitud, mark.longitud]} 
        icon={blackIcon} 
        eventHandlers={{ click: ()=> handleMarkerClick(mark,'Empalme','black')}}/>

      
    );
  });

  const markerControl = TotalTrafficControl?.map((mark, index) => {
    return (
        <Marker key={index} position={[mark.latitud, mark.longitud]} 
        icon={redIcon} eventHandlers={{ click: ()=> handleMarkerClick(mark,'Control','#BB2000')}}/>

    );
  });

  const markerLuces = TotalTrafficLuces?.map((mark, index) => {
    return (
        <Marker key={index} position={[mark.latitud, mark.longitud]} 
        icon={yellowIcon} eventHandlers={{ click: ()=> handleMarkerClick(mark,'Luces','#F1C40F ')}}/>
    );
  });

  return (
    <>
      <Helmet>
        <title> Maps | Tek Cloud </title>
      </Helmet>
      <Container>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={12} sm={2} md={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction={'column'} alignItems="center" justifyContent="space-between">
                  <Item >
                 <img alt="logo" src={user.logoUrl} style={{ width: user.roles==='user'? 90:130 }} />

                  </Item>
                </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={1} md={1} />
          
          <Grid item xs={4} sm={3} md={2}>
            <Stack direction={'column'} alignItems="center" justifyContent="center" spacing={1}>
              <Item sx={{ background: 'blue' }}>Total {TRAFFICLIGTHLIST?.length}</Item>
              <Item sx={{ background: '#228B22' }}>Online {TotalTrafficOnline?.length}</Item>
            </Stack>
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            <Stack direction={'column'} alignItems="end" justifyContent="flex-end" spacing={1}>
              <Item sx={{ background: '#6495ED' }}>Offline {TotalTrafficOffline?.length}</Item>
              <Item sx={{ background: 'black' }}>Empalme {TotalTrafficEmpalme?.length}</Item>
            </Stack>
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            <Stack direction={'column'} alignItems="end" justifyContent="flex-end" spacing={1}>
              <Item sx={{ background: 'red' }}>Control {TotalTrafficControl?.length}</Item>
              <Item sx={{ background: 'orange' }}>Luces {TotalTrafficLuces?.length}</Item>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={1} md={1} />

          <Grid item xs={12} sm={12} md={2}>
            <Stack direction={'column'} alignItems="center">
                <Item sx={{ background: '#008B8B' }}>
                Last Sync:
                <br />
                {hora.toLocaleDateString()}
                <br />
                {hora.toLocaleTimeString()}
              </Item>
            </Stack>
          </Grid>
        </Grid>

        <Card sx={{ height: '30rem' }}>
          <MapContainer center={[user.latitud, user.longitud]} zoom={user.roles === 'user' ? 13 : 9}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> | <a href="http://tekchile.cl">Tek Chile SA</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <LayersControl position="topright">
 
              <LayersControl.Overlay checked name="ONLINE">
                <MarkerClusterGroup chunkedLoading>{markerOperativo}</MarkerClusterGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay checked name="OFFLINE">
                <MarkerClusterGroup chunkedLoading>{markerOffline}</MarkerClusterGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay checked name="EMPALME">
                <MarkerClusterGroup chunkedLoading>{markerEmpalme}</MarkerClusterGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay checked name="CONTROL">
                <MarkerClusterGroup chunkedLoading>{markerControl}</MarkerClusterGroup>
              </LayersControl.Overlay>

              <LayersControl.Overlay checked name="LUCES">
                <MarkerClusterGroup chunkedLoading>{markerLuces}</MarkerClusterGroup>
              </LayersControl.Overlay>

            </LayersControl>
          </MapContainer>
          
        </Card>
        <Snackbar 
          open={open}
          onClose={handleClose}
          autoHideDuration={5000} 
          anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
          >
            <Alert icon={false} onClose={handleClose} sx={{ width: '100%',color:'white',backgroundColor:msgcolor }}>
             {message}
            </Alert>
          </Snackbar>
      </Container>
    </>
  );
};

export default Maps;
