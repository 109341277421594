import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Moment from 'react-moment';
import 'moment-timezone';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';

import { getDetails } from '../../redux/logDucks';
import Alert from '../../components/alerts/Alerts';
// components
import Label from '../../components/label';
import Scrollbar from '../../components/scrollbar';
// sections
import { LogListHead, LogListToolbar } from '../../sections/@dashboard/log';

// mock
import Loading from '../../components/loading/Loading';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'trafficligthId', label: 'Nombre', alignRight: false },
  { id: 'statustrafficligth', label: 'Estado', alignRight: false },
  { id: 'createdAt', label: 'Start_Events', alignRight: false },
  { id: 'updatedAt', label: 'End_Events', alignRight: false },
  { id: 'comment', label: 'Notas', alignRight: false },
  { id: 'periodo', label: 'Time_Events', alignRight: false }, // TODO cambiar en BD registro por organizacion
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DeatilsPage({token}) {
  const dispatch = useDispatch();

  const { state } = useLocation();
  const navigate = useNavigate();

  const code = state ? state.id:'';
  const org = state ? state.organizationId:'';
  const nameTraffic = state?state.name:'';

  const LOGLIST = useSelector((store) => store.log.details);
  const load = useSelector((store) => store.log.loadingLog);
  const err = useSelector((store) => store.log.errorLog);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
      dispatch(getDetails(token,code));
      setLoading(load);
  }, []);

  useEffect(() => {
    setLoading(load);
  }, [load]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('createdAt');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - LOGLIST.length) : 0;

  const filteredUsers1 = applySortFilter(LOGLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers1.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Details | Cloud Tek </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Details
          </Typography>
        </Stack>
        <Card>
          <LogListToolbar filterName={filterName} select={'details'} onFilterName={handleFilterByName} />
          {loading ? (
            <>
              <Loading />
              <Alert openT={loading} message={'Load Datails'} />
            </>
          ) : err===''? (
            <>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <LogListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={LOGLIST.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {filteredUsers1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, createdAt,updatedAt, statustrafficligth,comment } = row;
                      const deltaTime =  new Date(updatedAt) -new Date(createdAt);
                      let periodo;
                      const segundos = Math.floor(deltaTime/ 1000);
                      const minutos = Math.floor(segundos / 60);
                      const horas = Math.floor(minutos / 60);
                      const dias = Math.floor(horas / 24);

                      if(deltaTime/3600000>24)periodo = `${dias} Dias y ${horas % 24} Hrs`; 
                      if(deltaTime/3600000<24)periodo =`${horas % 24} Hrs y ${minutos % 60} Min`;
                      if(deltaTime/60000<60)periodo = `${minutos % 60} Min`;
                      if(deltaTime/1000<60)periodo = `${segundos % 60} Seg`;
                      


                      
                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" size="small">
                          <TableCell align="left" size="small">
                            <Typography variant="subtitle2" noWrap>
                            {id}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" size="small">
                            <Typography variant="subtitle2" noWrap>
                              {nameTraffic}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" size="small">
                            <Label
                              sx={{ width: '90%' }}
                              color={
                                statustrafficligth === "10111"
                                  ? 'primary'
                                  : statustrafficligth === "11011"
                                  ? 'default'
                                  : statustrafficligth === "11101"
                                  ? 'error'
                                  : statustrafficligth === "11110"
                                  ? 'warning'
                                  : 'success'
                              }
                            >
                              {statustrafficligth === "10111"
                                ? 'Tiempo Excede 2 Hrs'
                                : statustrafficligth === "11011"
                                ? 'Problemas con Empalme'
                                : statustrafficligth === "11101"
                                ? 'Problemas en Control'
                                : statustrafficligth === "11110"
                                ? 'Sin Luces hacia Terreno'
                                : 'Funcionamiento Normal'}
                            </Label>
                          </TableCell>
                          <TableCell align="left" size="small">
                            <Moment format='DD/MM/YY HH:mm' >{createdAt}</Moment>
                          </TableCell>
                          <TableCell align="left" size="small">
                            <Moment format='DD/MM/YY HH:mm' >{updatedAt}</Moment>
                          </TableCell>
                          <TableCell align="left" size="small">{comment}</TableCell>
                          <TableCell align="left" size="small">{periodo}</TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {LOGLIST.length === 0 && (
                    
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not Log Register
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            {LOGLIST.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={LOGLIST.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
            </>)
            :(<Alert openT={err!==''} message={"Ups!!! Error al cargar los Detalles"}/>)}
        </Card>
      </Container>
    </>
  );
}
