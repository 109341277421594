import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Moment from 'react-moment';

// @mui
import {
  Card,
  Table,
  Checkbox,
  Stack,
  Tooltip,
  IconButton,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { AssignmentOutlined, Download, Info, TrafficOutlined } from '@mui/icons-material';
import { getLogs,postExport} from '../../redux/logDucks';

// components
import Label from '../../components/label';
import Scrollbar from '../../components/scrollbar';
import Alert from '../../components/alerts/Alerts';
import Iconify from '../../components/iconify';
// sections
import { LogListHead, LogListToolbar } from '../../sections/@dashboard/log';

// mock
import Loading from '../../components/loading/Loading';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Code', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'statustrafficligth', label: 'Estado', alignRight: false },
  { id: 'updatedAt', label: 'DateTime', alignRight: false },
  { id: 'details', label: 'Options', alignRight: false },
];
const TABLE_HEAD1 = [
  { id: 'organizationId', label: 'Organization', alignRight: false }, // TODO cambiar en BD registro por organizacion
  { id: 'id', label: 'Code', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'statustrafficligth', label: 'Estado', alignRight: false },
  { id: 'updatedAt', label: 'DateTime', alignRight: false },
  { id: 'details', label: 'Options', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function LogPage({token,usuario}) {
  const dispatch = useDispatch();
  
  const LOGLIST = useSelector((store) => store.log.array);
  const load = useSelector((store) => store.log.loadingLog);
  const err = useSelector((store) => store.log.errorLog);

  const [loading, setLoading] = useState(false);
  const user = usuario

  useEffect(() => {
    dispatch(getLogs(token));
    setLoading(load);
  }, []);

  useEffect(() => {
    setLoading(load);
  }, [load]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState(user.roles === 'user'?'id':'organizationId');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const navigate = useNavigate();

  const handleDetails = (row) => {
    const {id,organizationId,name} = row
    navigate(`../details`, { state: {id,organizationId,name} });
  };

  const handleDownload = (trafficligth) => {
    const {id}= trafficligth
    dispatch(postExport(token,id))
   }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - LOGLIST.length) : 0;

  const filteredUsers = applySortFilter(LOGLIST, getComparator(order, orderBy), filterName);


  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Logs | Cloud Tek </title>
      </Helmet>

      <Container>
        <Card>
          <LogListToolbar filterName={filterName} select={'log'} onFilterName={handleFilterByName} />
          {loading ? (
            <>
              <Loading />
              <Alert openT={loading} message={"Load Information"}/>
            </>
          ) : (
            err===''? (
              <>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <LogListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={user.roles === 'user' ? TABLE_HEAD : TABLE_HEAD1}
                        rowCount={LOGLIST.length}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                          const { id, organizationId, name, statustrafficligth, updatedAt } = row;
              
                          return (
                            <TableRow hover key={id} tabIndex={-1} role="checkbox" size="small">
                     
                              {user.roles !== 'user' && (
                                <TableCell component="th" scope="row" size="small">
                                  <Typography variant="subtitle2" noWrap>
                                    {organizationId}
                                  </Typography>
                                </TableCell>
                              )}

                              <TableCell align="left" size="small">
                                <Typography variant="subtitle2" noWrap>
                                  {id}
                                </Typography>
                              </TableCell>
                              <TableCell align="left" size="small">
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </TableCell>

                              <TableCell align="left" size="small">
                                <Label
                                  sx={{ width: '30%',p:2}}
                                  color={
                                    statustrafficligth === "10111"
                                      ? 'primary'
                                      : statustrafficligth === "11011"
                                      ? 'default'
                                      : statustrafficligth === "11101"
                                      ? 'error'
                                      : statustrafficligth === "11110"
                                      ? 'warning'
                                      : 'success'
                                  }
                                >
                                  <Tooltip title= {statustrafficligth === "10111"
                                    ? 'Sin Info...'
                                    : statustrafficligth === "11011"
                                    ? 'Empalme'
                                    : statustrafficligth === "11101"
                                    ? 'Control'
                                    : statustrafficligth === "11110"
                                    ? 'Luces'
                                    : 'Operativo'} >
                                    <TrafficOutlined/>
                                  </Tooltip>
                               
                                  
                                </Label>
                              </TableCell>
                              <TableCell align="left" size="small">
                                <Moment format="DD/MM/YY">{updatedAt}</Moment>
                                ----
                                <Moment format="HH:mm:ss">{updatedAt}</Moment>
                              </TableCell>
                              <TableCell align="left" size="small">
                              <Stack justifyContent="left" alignItems="center" spacing={1} direction={'row'}>
                                  <Tooltip title="Detalles">
                                  <IconButton  onClick={()=>handleDetails(row)} color='primary'>
                                    <AssignmentOutlined/>
                                  </IconButton>
                                </Tooltip>
                                {
                                  user.roles==='admin' || user.roles==='Sadmin' ?
                                  <Tooltip title="Descargar" onClick={()=>handleDownload(row)}>
                                  <IconButton  color='success'>
                                    <Download/>
                                  </IconButton>
                                </Tooltip>:''
                                }                             
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 23 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Not found
                                </Typography>

                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using complete words.
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={LOGLIST.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ):(<Alert openT={err!==''} message={"Ups!!! Error al cargar el Log"}/>)
          )}
        </Card>
      </Container>
    </>
  );
}
